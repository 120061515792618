import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
// init Swiper:
const swiperMulti = new Swiper('.swipermulti', {
  modules: [Navigation, Pagination, Autoplay],

  slidesPerView: 4,
  spaceBetween: 24,
  direction: 'horizontal',
  loop: true,
  autoHeight: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplay: {
    delay: 3000,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-next-custom',
    prevEl: '.swiper-prev-custom',
  },

  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
    },
    // when window width is >= 480px
    580: {
      slidesPerView: 2,
    },
    // when window width is >= 640px
    720: {
      slidesPerView: 3,
    },
    959: {
      slidesPerView: 4,
    },
  },
});

export default swiperMulti;
