import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
// init Swiper:
const swiperLogo = new Swiper('.swiperlogo', {
  modules: [Navigation, Pagination, Autoplay],

  // slidesPerView: 4,
  //freeMode: true,
  spaceBetween: 80,
  //grabCursor: true,
  slidesPerView: 7,
  loop: true,
  autoplay: {
    delay: 1,
    disableOnInteraction: true,
  },
  freeMode: true,
  speed: 3200,
  freeModeMomentum: false,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
    },
    720: {
      slidesPerView: 7,
    },
  },
  
});


export default swiperLogo;
