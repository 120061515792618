/*$(window).bind('mousewheel', function(event) {
    if (event.originalEvent.wheelDelta >= 0) {
        $('.header-wrapper').removeClass('header-up');
        //$('.c-header').addClass('no-scroll');
    }
    else {
        $('.header-wrapper').addClass('header-up');
    }
})*/

/*var scrollBefore = 0;

window.addEventListener('scroll',function(e){
    const scrolled = window.scrollY;

    if(scrollBefore > scrolled){
        console.log("ScrollUP");
        scrollBefore = scrolled;
        $('.header-wrapper').removeClass('header-up');
        //Desired action
    }else{
        scrollBefore = scrolled;
        console.log("ScrollDOWN");
        $('.header-wrapper').addClass('header-up');
        //Desired action
    }

})*/

import $ from 'jquery';


window.onresize = function() {
    document.body.height = window.innerHeight;
  }
  window.onresize();
  
  (function(){
  
    var doc = document.documentElement;
    var w = window;
  
    var prevScroll = w.scrollY || doc.scrollTop;
    var curScroll;
    var direction = 0;
    var prevDirection = 0;
    var scroll = $(window).scrollTop();
  
   
  
    var header = document.getElementById('site-header');
  
    var checkScroll = function() {
  
      /*
      ** Find the direction of scroll
      ** 0 - initial, 1 - up, 2 - down
      */
  
      curScroll = w.scrollY || doc.scrollTop;
      if (curScroll > prevScroll) { 
        //scrolled up
        direction = 2;
      }
      else if (curScroll < prevScroll) { 
        //scrolled down
        direction = 1;
      }
  
      if (direction !== prevDirection) {
        toggleHeader(direction, curScroll);
      }
      
      prevScroll = curScroll;
    };
  
  
    var toggleHeader = function(direction, curScroll) {
      if (direction === 2 && curScroll > 100) { 
        header.classList.add('header-up');
        //header.classList.add('hide');
        prevDirection = direction;
      }
      else if (direction === 1) {
        //header.classList.remove('hide');
        header.classList.remove('header-up');
        prevDirection = direction;
      }
    };
    
    window.addEventListener('scroll', checkScroll);
  
  })();