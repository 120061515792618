/*
  Project: Safety Films
  Author: tobias lorenz
 */
import $ from 'jquery';
import greet from './modules/greeting';
import header from './additional/header';
import googleMaps from './additional/googleMaps';

import swiper from './additional/swiper';
import swiperMulti from './additional/swiper-multi';
import swiperLogo from './additional/swiper-logo';


greet('World');

let produktImageId = 'Sicherheitsfolie';

function openMenu() {
  const nav = document.getElementById('navigation');
  const overlay = document.getElementById('overlay');
  if (nav.classList.contains('visible')) {
    nav.classList.remove('visible');
    document.querySelector('html').classList.remove('no-scroll');
  } else {
    nav.classList.add('visible');
    document.querySelector('html').classList.add('no-scroll');
  }

  if (overlay.classList.contains('opacity-08')) {
    overlay.classList.remove('opacity-08');
  } else {
    overlay.classList.add('opacity-08');
  }
}

function openContact() {
  const contact = document.getElementById('beratung');
  const overlay = document.getElementById('overlay-beratung');

  if (contact.classList.contains('visibility-hidden')) {
    contact.classList.remove('visibility-hidden');
    document.querySelector('html').classList.add('no-scroll');
  } else {
    contact.classList.add('visibility-hidden');
    document.querySelector('html').classList.remove('no-scroll');
  }

  if (overlay.classList.contains('opacity-08')) {
    overlay.classList.remove('opacity-08');
  } else {
    overlay.classList.add('opacity-08');
  }
}

function openFaq(e) {
  const antwort = e.nextElementSibling;

  if (antwort.classList.contains('show-answer')) {
    antwort.classList.remove('show-answer');
  } else {
    antwort.classList.add('show-answer');
  }

  if (e.classList.contains('turn-icon')) {
    e.classList.remove('turn-icon');
  } else {
    e.classList.add('turn-icon');
  }
}

document.querySelector('.burger-nav').addEventListener('click', () => {
  openMenu();
});

document.querySelector('.burger-close').addEventListener('click', () => {
  openMenu();
});

//Check if #kontakt is in URL
window.addEventListener('popstate', function () {
  if (window.location.href.includes('#kontakt')) {
    openContact();
  }
});

document.querySelectorAll('.frage').forEach((elem) =>
  elem.addEventListener('click', (e) => {
    // console.log(e.currentTarget);
    openFaq(e.currentTarget);
  }),
);


document.querySelector('#overlay').addEventListener('click', () => {
  openMenu();
});

document
  .querySelector('#beratung-open-header')
  .addEventListener('click', () => {
    openContact();
  });

document
  .querySelector('#beratung-close')
  .addEventListener('click', () => {
    openContact();
  });

document
  .querySelector('#overlay-beratung')
  .addEventListener('click', () => {
  openContact();
});

// Wert des ausgewählten Filters in den Local Storage schreiben
//document.querySelector('.category-filter').addEventListener('click', (event) => {
//  var newActiveFilter = event.target.name;
//  localStorage.setItem('Filter', newActiveFilter);
//});

if(document.querySelector('.category-filter')){
document
  .querySelector('.category-filter')
  .addEventListener('click', (el) => {
    var newActiveFilter = el.target.name;
    localStorage.setItem('Filter', newActiveFilter);
  });

  //Aktiven Filter aus Local Storage bekommen
function setActiveFilter() {
  const activeFilter = localStorage.getItem('Filter');
  const activeInput = document.getElementById(`${activeFilter}`);
  activeInput.classList.add('active');
};

setActiveFilter();
}



import productImage from './additional/productImage';
