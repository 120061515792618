// Entfernt die Sichtbarkeits-Klasse von allen Produkt-Teaser-Bildern
function removeImage() {
    elements = document.querySelectorAll('.produkt-image');

    elements.forEach(elem => {
        if(elem.classList.contains('produkt-visibility-visible')){
            elem.classList.remove('produkt-visibility-visible');
        }
    });
}

window.addEventListener("load", ()=>{
    // Zeigt initial das erste Produktbild anzeigen
    if(document.querySelector('.produkt-image')){
        document.querySelector('.produkt-image').classList.add('produkt-visibility-visible');
    };

    // Bei Hover des entsprechenden Links wird nur das Produkt-Bild mit der entsprechenden ID sichtbar
    document.querySelectorAll('.produktlink-teaser-nav').forEach(elem => elem.addEventListener("mouseover",(event) => {
        
        if (event.target.id !== '') {
            removeImage();
            produktImageId = event.target.id;

            var elm = document.querySelector('div[class~="produkt-image"][id~="'+produktImageId+'"]');
            elm.classList.add('produkt-visibility-visible');
            //var elm = document.querySelector('div[class~="produkt-image"][id~="'+produktImageId+'"]');
        }
    })); 
});
